import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { onAuthUIStateChange, CognitoUserInterface, AuthState } from '@aws-amplify/ui-components';
import { Amplify, Hub, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

import { Observable } from 'rxjs';
import { User } from './store/models/user';
import { GetUser } from './store/actions/user.action';
import { UserState } from './store/states/user.state';
import { BaseuserComponent } from './components/baseuser/baseuser.component';
// import { ROARR, Roarr as log } from 'roarr';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseuserComponent {

  services = {
    async handleSignUp(formData: Record<string, any>) {
      let { username, password, attributes } = formData;
      // custom username
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();
      const obj = {
        username,
        password,
        attributes,
      }
      console.log(obj)
      return Auth.signUp(obj);
    },
    async handleSignIn(formData: Record<string, any>) {
      let { username, password } = formData;
      // custom username
      username = username.toLowerCase();
      const obj = {
        username,
        password,
      }
      // console.log(obj)
      return Auth.signIn(obj);
    },
    async handleForgotPassword(username: string) {
      // let { username } = formData;
      // custom username
      username = username.toLowerCase();
      console.log(username)
      return Auth.forgotPassword(username);
    },
  };

  constructor(
    public authenticator: AuthenticatorService,
    private store: Store,
  ) {
    super()
    Amplify.configure(awsconfig);
  }

  ngOnInit() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          // log('user signed in');
          this.initUser()
          break;
        case 'signUp':
          // log('user signed up');
          break;
        case 'signOut':
          // log('user signed out');
          break;
        case 'signIn_failure':
          // log('user sign in failed');
          break;
        case 'configured':
          // log('the Auth module is configured');
      }
    });

     Auth.currentUserInfo().then(u => {
      // console.log(JSON.stringify(u))
      if (u) {
        if (Object.keys(u).length == 0) {
          Auth.signOut().then(() => {
          window.location.href = '/portal'
        });
        } else {
          this.initUser()
        }
      }
    });
    // this.myPromise.then(u=>log(u))     
  }

  initUser = () => {
    // log('User init started');
    this.store.dispatch(new GetUser()).toPromise();
  }

  // myPromise = new Promise((resolve) => {
  //   Auth.currentUserInfo().then(uu => resolve(uu));
  // });

}
